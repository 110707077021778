








































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { CertificateTemplate } from '@/shared/model/certificateTemplate'
import certificationService from '@/shared/services/certificationService'
import userService from '@/shared/services/userService'

// @ts-ignore
import { VueEditor } from 'vue2-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

export default Vue.extend({
  name: 'ReportWizard',
  components: {
    VueEditor,
  },

  props: {
    selectedCertificateTemplate: {
      type: Object,
      default: () => undefined,
    },
  },

  data () {
    return {
      tab: 0,
      // tabs: ['Daten', 'Infos', 'Anhang'],
      tabs: [this.$t('data').toString(), this.$t('info').toString(), this.$t('attachment').toString()],

      searchInput: [] as string[],
      loading: false,

      certificateTemplate: new CertificateTemplate(),
      trainingDataFile: undefined as File | undefined,

      htmlAttachment: '',
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      certificationAuthority: 'selectedCertificationAuthority',
    }),
    availableSteps () {
      const steps = [0]

      if (this.certificateTemplate.name && this.certificateTemplate.certificateName && Number(this.certificateTemplate.validForYears)) {
        steps.push(1)
      }

      if (steps.includes(1)) {
        steps.push(2)
      }

      if (steps.includes(2)) {
        steps.push(3)
      }

      return steps
    },
    userIsSystemAdmin () {
      return userService.userIsSystemAdmin()
    },
  },
  async created () {
    if (this.selectedCertificateTemplate) {
      this.certificateTemplate = this.selectedCertificateTemplate
      if (this.certificateTemplate.trainingDataFile && this.certificateTemplate.trainingDataFileName) {
        this.trainingDataFile = new File([], this.certificateTemplate.trainingDataFileName)
      }
      if (this.certificateTemplate.htmlAttachment) {
        this.htmlAttachment = this.certificateTemplate.htmlAttachment
      }
    } else {
      this.certificateTemplate.certificationAuthority = this.certificationAuthority._id
      this.certificateTemplate.pdfTemplate = 'default'
      this.certificateTemplate.validForYears = 3
    }
  },
  methods: {
    async next (valid: any) {
      if (this.tab === this.tabs.length - 1) {
        await this.clickSave()
      } else {
        this.tab++
      }
    },

    setTrainingData () {
      var reader = new FileReader()
      if (this.trainingDataFile) {
        this.certificateTemplate.trainingDataFileName = this.trainingDataFile.name
        reader.readAsDataURL(this.trainingDataFile)
        reader.onload = () => {
          if (reader.result) {
            this.certificateTemplate.trainingDataFile = reader.result.toString()
          }
        }
      }
    },

    deleteTrainingData () {
      this.trainingDataFile = undefined
      this.certificateTemplate.trainingDataFile = undefined
      this.certificateTemplate.trainingDataFileName = undefined
    },

    async clickSave () {
      this.loading = true
      this.certificateTemplate.htmlAttachment = this.htmlAttachment
      if (!this.certificateTemplate._id) {
        this.axios
          .post(
            `/api/certificationauthority/${this.certificateTemplate.certificationAuthority}/template`,
            this.certificateTemplate,
          )
          .then((result) => {
            if (result) {
              this.$toast(this.$t('dialog.certificate-template-save-success').toString())
              certificationService.getCertificateTemplates(this.$store, this.certificationAuthority._id)
            }
            this.$emit('wizardfinished')
            this.loading = false
          })
      } else {
        this.axios
          .put(
            `/api/certificationauthority/${this.certificateTemplate.certificationAuthority}/template/${this.certificateTemplate._id}`,
            this.certificateTemplate,
          )
          .then((result) => {
            if (result) {
              this.$toast(this.$t('dialog.certificate-template-save-success').toString())
              certificationService.getCertificateTemplates(this.$store, this.certificationAuthority._id)
            }
            this.$emit('wizardfinished')
            this.loading = false
          })
      }
    },
  },
})
