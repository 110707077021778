/* eslint-disable no-use-before-define */

// see https://medium.com/@agentwhs/complete-guide-for-typescript-for-mongoose-for-node-js-8cc0a7e470c1

export interface ICertificateTemplate {
  _id?: string
  name?: string
  certificateName?: string
  standards?: string
  textBlock?: string
  htmlAttachment?: string
  level?: string
  validForYears?: number
  certificationAuthority?: string
  pdfTemplate?: string
  trainingDataFile?: string | null
  trainingDataFileName?: string | null
  sortIndex?: number
  canCreateReports?: boolean
  createdAt?: Date
  updatedAt?: Date
}

export class CertificateTemplate implements ICertificateTemplate {
  constructor (
    public _id?: string,
    public name?: string,
    public certificateName?: string,
    public standards?: string,
    public htmlAttachment?: string,
    public textBlock?: string,
    public level?: string,
    public validForYears?: number,
    public certificationAuthority?: string,
    public pdfTemplate?: string,
    public trainingDataFile?: string | null,
    public trainingDataFileName?: string | null,
    public sortIndex?: number,
    public canCreateReports?: boolean,
    public createdAt?: Date,
    public updatedAt?: Date,
  ) {
    return this
  }
}
